import React, { useState, useEffect } from 'react';
import api from '../components/api';
import { ACCESS_TOKEN } from '../constants';
import { jwtDecode } from 'jwt-decode';
import '../styles/Profile.css';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [miningActivities, setMiningActivities] = useState([])

  const token = localStorage.getItem(ACCESS_TOKEN);
  const user = jwtDecode(token)
  
  useEffect(() => {
    getActivities();
  }, []);

  const handleDeleteClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setUserName('');
  };

  const handleUsernameChange = (e) => {
    setUserName(e.target.value);
  };

  const getActivities = () => {
    api
    .get("/v1/mvynes/t-history/")
    .then((res) => res.data)
    .then((data) => {
      const updatedData = data.map(activity => {
        const transactionTime = new Date(activity.transaction_time);
        const currentTime = new Date();
        const timeDifference = currentTime - transactionTime;
        const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutesAgo = Math.floor(timeDifference / (1000 * 60));
        const timeAgo = hoursAgo > 24 
          ? transactionTime.toLocaleDateString() 
          : hoursAgo > 0 
            ? `${hoursAgo} hours ago` 
            : `${minutesAgo} minutes ago`;
        return { 
          ...activity, 
          timeAgo 
        };
      }).sort((a, b) => new Date(b.transaction_time) - new Date(a.transaction_time));
      setMiningActivities(updatedData.slice(0, 4));
      console.log(updatedData);
    })
    .catch((err) => alert(err));
  }

  const navigate = useNavigate()

  const handleDeleteConfirm = async () => {
    const user = jwtDecode(token)
    const username = user.username
    if (userName === username) {
      await api.delete("/v1/user/delete/")
      // Perform delete action
      console.log(token.username)
      console.log('User deleted');
      localStorage.clear();
      navigate("/");
    } else {
      alert('Username does not match');
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="profile-info">
          <h1>{user.first_name} {user.last_name}</h1>
          <p>@{user.username}</p>
          <p className="bio">Vynechain user | Miner </p>
          <div className='profile-buttons-container'>
            <button className="edit-profile-btn">Edit Profile</button>
            <button className="delete-profile-btn" onClick={handleDeleteClick}>Delete Profile</button>
          </div>
          
        </div>
      </div>

      <div className="profile-stats">
        <div className="stat-item">
          <span className="stat-value">150</span>
          <span className="stat-label">Transactions</span>
        </div>
        <div className="stat-item">
          <span className="stat-value">1.2K</span>
          <span className="stat-label">Connections</span>
        </div>
        <div className="stat-item">
          <span className="stat-value">45</span>
          <span className="stat-label">NFTs</span>
        </div>
        <div className="stat-item">
          <span className="stat-value">300</span>
          <span className="stat-label">Mined VYN</span>
        </div>
        <div className="stat-item">
          <span className="stat-value">50</span>
          <span className="stat-label">Mining Streak</span>
        </div>
      </div>

      <div className="profile-content">
        <div className="profile-section">
          <h2>Recent Mining Activity</h2>
          {miningActivities.map((activity) =>
            <div className="activity-list">
              <div className="activity-item">
                <p>{activity.transaction_type} {activity.vyne_amount} VYN</p>
                <span>{activity.timeAgo}</span>
              </div>
            </div>)}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Confirm Delete</h2>
            <p>Please enter your username to confirm deletion:</p>
            <input
              type="text"
              value={userName}
              onChange={handleUsernameChange}
              placeholder="Enter username"
            />
            <div className="modal-buttons">
              <button className="confirm-delete-btn" onClick={handleDeleteConfirm}>Confirm</button>
              <button className="cancel-delete-btn" onClick={handleModalClose}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
